import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import notify from "../../components/notifier";
import LoginService from "../../services/login";
import LiveMsgService from "../../services/liveMsg";
import UserTokenService from "../../services/user";
import Slider from "../../Pages/Slider";
import userDetail from "../../services/user";
// import MatchService from "../../services/match";
import moment from "moment";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Form,
  FormGroup,
  Col,
  Label,
  Input,
  Alert,
  Row,
  InputGroup,
  InputGroupText
} from "reactstrap";
import Withdrawl from "../../Pages/Wallet/Withdrawl";
import login from "../../services/login";

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

const items = [
  {
    src: process.env.PUBLIC_URL + "/assets/images/cricket.png",
  },
  {
    src: process.env.PUBLIC_URL + "/assets/images/football.png",
  },
  {
    src: process.env.PUBLIC_URL + "/assets/images/tennis.png",
  },
];

class DefaultsideNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: {
        ClientName: "",
        Password: "",
        oid: "Sportsx9",
        a_login:true
      },
      signup: {
        ClientName: "",
        Password: "",
        Email: "",
        Phone: "",
        otp: "",
        u_otp: "",
        AccountType: "User",
      },
      passwordMsg: "",
      userAvailableMsg: "",
      otpprocess: false,
      user: [],
      notifications: [],
      loginFormShow: false,
      liveMsgs: [],
      sportsList: [],
      activeIndex: 0,
      modal: false,
      casinoModel: false,
      fade: false,
      loginModal: false,
      loginFade: false,
      isProcess: false,
      logoutAlert: false,
      seconds: 60,
      is_landing: false,
      showPassword: false,
      websettings: [],
      showOtpTextbox: false,
      verifyotpprocess: false,
      verifyOtp: false,
      notVerifyOtp: true,
      verifyOtpMsg: "",
      modalView: false,
      is_login: '',
      balancemount: 0,
      totalExposure: 0
    };

    this.signupModal = this.signupModal.bind(this);

    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  componentDidMount = () => {
    let path = this.props.history.location.pathname;
    let is_welcome_msg = localStorage.getItem('welcomemsg')
    if (is_welcome_msg) {
      this.setState({
        modalView: true
      })
    }
    let is_landing = this.props.has_landing;
    this.setState({
      is_landing: is_landing,
      // websettings:settings
    });
    let settings = this.props.settings;
    if (settings.length > 0) {
      this.setState({
        websettings: settings,
      });
    } 
    // else {
    //   userDetail.webSettings().then((response) => {
    //     this.setState({ websettings: response.data.data });
    //   });
    // }

  };

  loginTimeCount = () => {
    const oldTimeObj = JSON.parse(localStorage.getItem("loginUserInfo"));
    const oldTime = oldTimeObj && oldTimeObj.user.logoutMessageTime;
    let currentTime = moment().format("hh:mm A");
    // console.log('time different', oldTime - currentTime, currentTime, oldTime)
    // this.setState({seconds: oldTime});
    if (currentTime === oldTime || currentTime > oldTime) {
      console.log("data currnt time logout alert s3t", currentTime === oldTime);
      if ((currentTime === oldTime) === true) {
        console.log("true dsdddd");
        this.setState({ logoutAlert: true });
      }
    } else {
      this.setState({ logoutAlert: false });
    }
  };

  handleChange = ({ target }) => {
    this.setState((state, props) => {
      let login = Object.assign({}, state.login);
      let value = '';
      if (target.name == 'ClientName') {
        value = target.value && target.value.trim()
      } else {
        value = target.value;
      }
      login[target.name] = value;
      return { login };
    });
  };

  validateUserAccount = () => {
    const login = this.state.login;
    if (!login.ClientName) {
      notify("Username can not empty", "error", "top-left");
      return false;
    }
    if (!login.Password) {
      notify("Password can not empty", "error", "top-left");
      return false;
    }
    return true;
  };

  submitLogin = (e) => {
    // console.log('SUBMIT LOGIN ==========', this.state.login)
    e.preventDefault();
    if (!this.validateUserAccount()) {
      return false;
    }
    this.setState({ isProcess: true });
    // let logindata = this.state.login;
    // logindata.deviceId = 'trynew';
    LoginService.userLogin(this.state.login)
      .then((response) => {
        if (response.status === 200) {
          this.setState({ isProcess: false });
          this.loginModal();
          response.data.user["logoutMessageTime"] = moment()
            .add(29, "minutes")
            .format("hh:mm A");
          // console.log('NTITIN RES', )
          localStorage.setItem("loginUserInfo", JSON.stringify(response.data));
          if(response.data.user && response.data.user.deviceID){
            localStorage.setItem("deviceID", response.data.user.deviceID);
          }
          localStorage.setItem(
            "@access:token",
            JSON.stringify(response.headers["x-auth-token"])
          );
          if (response.data.user.PasswordChanged === 0) {
            this.props.history.push("/ChangePassword");
            window.location.reload();
          } else {
            notify("Login successfully", "success", "top-left");
            this.props.history.push("/Sports/CRCKT");
            localStorage.setItem('welcomemsg', true)
            window.location.reload();
          }
          this.props.onLogIn();
          // console.log(
          //   "Successfully Login",
          //   JSON.parse(localStorage.getItem("loginUserInfo"))
          // );
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          this.setState({ isProcess: false });
          notify(error.response.data, "error", "top-left");
        }
      });
  };

  logoutHandler = (e) => {
    LoginService.userLogoutStatus()
      .then((res) => {
        console.log("logoutHnadler=", res.data);
        if (res.data.data.LogedIn === 0) {
          localStorage.clear();
          this.props.onLogout();
          // this.props.history.replace("/");
          window.location.reload();
          console.log("Logout successfully..");
        }
      })
      .catch((err) => console.log(err.message));
  };

  betHistory = () => {
    this.props.history.push("/betHistory");
  };

  AccountDetail = () => {
    this.props.history.push("/wallet/AccountDetail")
  }

  myBets = () => {
    // this.props.history.push("/myBets");
    this.props.history.push("/myMarkets");
  };

  accountStatement = () => {
    this.props.history.push("/accountStatement");
  };

  changePassword = () => {
    this.props.history.push("/changePassword");
  };

  stackSetting = () => {
    this.props.history.push("/stackSetting");
  };
  home = () => {
    this.props.history.push("/");
  };

  showExposures = () => {
    this.props.history.push("/ExpousureBets");
  };

  visibleLoginForm = () => {
    this.setState((state) => ({ loginFormShow: !state.loginFormShow }));
  };

  visibleSearchForm = () => {
    this.setState((state) => ({ searchGameShow: !state.searchGameShow }));
  };

  toggleGame = (status) => {
    // setToggleGameMenu(status);
  };

  // sportsDetail = (id) => {
  //   this.props.history.push("/detail/" + id );
  //   window.location.reload();
  // };

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === items.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? items.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  signupModal = () => {
    // console.log("hello signup");
    this.setState({
      modal: !this.state.modal,
      // loginModal: !this.state.loginModal,
    });
  };
  direct = (path) => {
    localStorage.removeItem('welcomemsg')
    this.setState({ modalView: !this.state.modalView })
    this.props.history.push(path)
  }
  togglemodal = () => {
    this.setState({ modalView: !this.state.modalView })
    localStorage.removeItem('welcomemsg')
  }
  casinoContentModel = () => {
    this.setState({
      casinoModel: !this.state.casinoModel,
    });
    this.props.history.push("/CasinoList");
  };

  loginModal = (data) => {
    let loginState = {};
    if (data === "login") {
      loginState = {
        loginModal: !this.state.loginModal,
        modal: !this.state.modal,
      };
    } else {
      loginState = {
        loginModal: !this.state.loginModal,
      };
    }
    this.setState(loginState);
  };

  validateSignupAccount = () => {
    let regex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    const signup = this.state.signup;
    // console.log('PASSWORD NOT ERIFIYDKD DKDDD', this.state.notVerifyOtp);
    if (!signup.ClientName) {
      notify("Username can not empty", "error", "top-left");
      //signup.ClientName.current.focus();
      return false;
    }
    // if (!signup.Password || !signup.Password.match(regex)) {
    //   notify(
    //     "Minimum 8 letter password, with at least a special character, upper and lower case letters and a number",
    //     "error",
    //     "top-left"
    //   );
    //   return false;
    // }

    if (!signup.Password || signup.Password.length < 8) {
      notify(
        "Minimum 8 letter password",
        "error",
        "top-left"
      );
      return false;
    }

    if (!signup.Phone) {
      notify("Please Enter Valid Phone Number", "error", "top-left");
      return false;
    }
    if (!signup.u_otp) {
      notify("Please Enter OTP", "error", "top-left");
      return false;
    }

    // if (this.state.notVerifyOtp) {
    //   notify("Please Enter OTP", "error", "top-left");
    //   return false;
    // }

    // if (!signup.Email) {
    //   notify("Email can not empty", "error", "top-left");
    //   return false;
    // }

    // if (signup.otp != signup.u_otp) {
    //   notify("Please Enter Valid OTP", "error", "top-left");
    //   return false;
    // }

    return true;
  };

  handleSignupChange = ({ target }) => {
    if (target.name === 'Password' && target.value.length < 8) {
      this.setState({ passwordMsg: "Minimum 8 letter password" });
    } else {
      this.setState({ passwordMsg: "" });
    }
    this.setState((state, props) => {
      let signup = Object.assign({}, state.signup);
      let svalue = target.value;
      if (target.name == 'ClientName') {
        svalue = svalue.replace(/\s+/g, '');
      }
      console.log('svalue', svalue);
      signup[target.name] = svalue;
      return { signup };
    });
  };

  signupSubmit = (e) => {
    e.preventDefault();
    if (!this.validateSignupAccount()) {
      return false;
    }
    // console.log(this.state.signup);
    // return;
    this.setState({ isProcess: true });
    //verify otp code
    const { Phone, u_otp, ClientName, Password } = this.state.signup;
    //===================START USER VERIFY OTP====================

    userDetail.userVerifyOtp(Phone, u_otp).then((response) => {
      const { data } = response;
      console.log("VERFIY OTP MSG RESPONSE ======", data);
      if (data.type == "success") {
        console.log("VERFIY OTP MSG RESPONSE", data);

        //===================START USER SIGNUP====================

        LoginService.userSingup(this.state.signup)
          .then((response) => {
            if (response.status === 200) {

              //========================signup then login automatic=====================================
              this.setState(state => ({
                login: { ...state.login, ClientName: ClientName, Password: Password }
              }));
              // let logindata = this.state.login;
              // logindata.deviceId = 'trynew';
              LoginService.userLogin(this.state.login)
                .then((response) => {
                  if (response.status === 200) {
                    this.setState({ isProcess: false });
                    this.loginModal();
                    response.data.user["logoutMessageTime"] = moment()
                      .add(29, "minutes")
                      .format("hh:mm A");
                    localStorage.setItem("loginUserInfo", JSON.stringify(response.data));
                    if(response.data.user && response.data.user.deviceID){
                      localStorage.setItem("deviceID", response.data.user.deviceID);
                    }
                    localStorage.setItem(
                      "@access:token",
                      JSON.stringify(response.headers["x-auth-token"])
                    );
                    if (response.data.user.PasswordChanged === 0) {
                      this.props.history.push("/ChangePassword");
                      window.location.reload();
                    } else {
                      notify("Login successfully", "success", "top-left");
                      this.props.history.push("/welcome");
                      window.location.reload();
                    }

                    this.props.onLogIn();
                    console.log(
                      "Successfully Login",
                      JSON.parse(localStorage.getItem("loginUserInfo"))
                    );
                  }
                })
                .catch((error) => {
                  if (error.response.status === 400) {
                    this.setState({ isProcess: false });
                    notify(error.response.data, "error", "top-left");
                  }
                });

              //===========================end login query=======================================

              this.setState({ isProcess: false });
              // notify("Singup successfully", "success", "top-left");
              this.signupModal();
            }
          })
          .catch((error) => {
            console.log('EOROROROR', error)
            if (error.response.status === 400) {
              this.setState({ isProcess: false });
              notify(error.response.data, "error", "top-left");
            }
          });

        this.setState({
          verifyotpprocess: false,
          showOtpTextbox: true,
          verifyOtp: true,
          verifyOtpMsg: data.message,
          notVerifyOtp: false,
          isProcess: false
        });

      } else if (data.type == "error") {
        notify(data.message, "error", "top-left");
        this.setState({
          verifyotpprocess: false,
          showOtpTextbox: true,
          verifyOtp: false,
          verifyOtpMsg: data.message,
          notVerifyOtp: true,
          isProcess: false
        });
      }
    });


    //end verify otp
    // LoginService.userSingup(this.state.signup)
    //   .then((response) => {
    //     if (response.status === 200) {
    //       this.setState({ isProcess: false });
    //       notify("Singup successfully", "success", "top-left");
    //       this.signupModal();
    //     }
    //   })
    //   .catch((error) => {
    //     if (error.response.status === 400) {
    //       this.setState({ isProcess: false });
    //       notify(error.response.data, "error", "top-left");
    //     }
    //   });
  };
  //#region user not login
  notLoginMessage = () => {
    notify("Please Login", "error", "top-right");
    // console.log("please login");
  };
  //#endregion user not login
  sendOTP = () => {
    this.setState({
      otpprocess: true,
    });
    console.log("this.state.signup", this.state.signup);
    if (
      this.state.signup.Phone == "" ||
      !this.state.signup.Phone ||
      this.state.signup.Phone.length != 10
    ) {
      notify("Please Enter Valid Phone Number", "error", "top-right");
      this.setState({
        otpprocess: false,
      });
      return false;
    }
    userDetail.userSendOtp(this.state.signup.Phone).then((response) => {
      const { data } = response;
      if (data.type == "success") {
        console.log("OTOP MSG RESPONSE", data);
        this.setState({ otpprocess: false, showOtpTextbox: true });
      }
    });

    // setTimeout(() => {
    //   this.setState({
    //     otpprocess:false,
    //     otp:12345
    //   })
    // }, 2000);
  };

  verifyOTP = () => {
    this.setState({
      verifyotpprocess: true,
    });
    console.log("this.state.signup", this.state.signup);
    if (
      this.state.signup.u_otp == "" ||
      !this.state.signup.u_otp ||
      this.state.signup.u_otp.length != 4
    ) {
      notify("Please Enter Valid OTP Number", "error", "top-right");
      this.setState({
        verifyotpprocess: false,
      });
      return false;
    }
    const { Phone, u_otp } = this.state.signup;
    userDetail.userVerifyOtp(Phone, u_otp).then((response) => {
      const { data } = response;
      console.log("VERFIY OTP MSG RESPONSE ======", data);
      if (data.type == "success") {
        console.log("VERFIY OTP MSG RESPONSE", data);
        this.setState({
          verifyotpprocess: false,
          showOtpTextbox: true,
          verifyOtp: true,
          verifyOtpMsg: data.message,
          notVerifyOtp: false,
        });
      } else if (data.type == "error") {
        this.setState({
          verifyotpprocess: false,
          showOtpTextbox: true,
          verifyOtp: false,
          verifyOtpMsg: data.message,
          notVerifyOtp: true,
        });
      }
    });
  };

  checkClientNameAvailibility = () => {
    const ClientName = this.state.signup.ClientName;
    userDetail.checkClientNameAvailibilty({ ClientName })
      .then((response) => {
        this.setState({ userAvailableMsg: "" });

      }).catch((error) => {
        if (error.response.status === 400) {
          console.log('error msg user availablifi', error.response.data);
          this.setState(state => ({
            signup: { ...state.signup, ClientName: "" },
            userAvailableMsg: error.response.data,
          }));
        }
      });

  }

  render() {
    const { balancemount, totalExposure } = this.state;
    // console.log(this.props.user)
    // let totalExposure = 0;
    // this.props.user &&
    //   this.props.user.GamewiseExposure &&
    //   this.props.user.GamewiseExposure.forEach((expo) => {
    //     totalExposure += expo.UsedExposure;
    //   });
    // let balancemount = 0;
    // if (this.props.user && this.props.user.BalanceAmount) {
    //   balancemount = this.props.user.BalanceAmount;
    //   if (totalExposure && totalExposure > 1) {
    //     balancemount = balancemount - totalExposure;
    //   }
    // }
    // let [notifications] = this.state.notifications;
    // console.log('notifications', this.state.notifications)
    // const { activeIndex } = this.state;

    // const slides = items.map((item) => {
    // 	return (
    // 		<CarouselItem
    // 			onExiting={this.onExiting}
    // 			onExited={this.onExited}
    // 			key={item.src}
    // 		>
    // 			<img src={item.src} alt={item.altText} />
    // 		</CarouselItem>
    // 	);
    // });

    // const gameType = this.props.location.pathname;
    const img = process.env.PUBLIC_URL + "/assets/images/sportsImg.jpg";
    // console.log('setingggggs',this.state.websettings)
    return (
      <React.Fragment>
        <div className="side-nav-sec">
          <ul className="side-menu-items">
            <li className="active">
              <a href="/">
                <img className="mnu-icn" alt="home" src="/assets/images/orange-hme.png" />
                Home
              </a>
            </li>
            <li>
              {
                this.props.user && this.props.user.ClientName
                ?
                  <a href="/myMarkets">
                    <img className="mnu-icn" alt="my-market" src="/assets/images/my-market.png" />
                    My-Market
                  </a>
                :
                  <a href="/" onClick={() => notify("Please Login first", "error", "top-right")}>
                    <img className="mnu-icn" alt="my-market" src="/assets/images/my-market.png" />
                    My-Market
                  </a>
              }
              
            </li>
            <li>
              <a href="/inplay">
                <img className="mnu-icn" alt="inplay" src="/assets/images/inplay.png" />
                In-Play
              </a>
            </li>
            <li>
              <a href="/Sports/CRCKT">
                <img className="mnu-icn" alt="cri-icn" src="/assets/images/cri-icn.png" />
                Cricket
              </a>
            </li>
            <li>
              <a href="/Sports/Soccer">
                <img className="mnu-icn" alt="soccer" src="/assets/images/soccer.png" />
                Soccer
              </a>
            </li>
            <li>
              <a href="/Sports/TNNS">
                <img className="mnu-icn" alt="tennis-icn" src="/assets/images/tennis-icn.png" />
                Tennis
              </a>
            </li>
            <li>
              {
                this.props.user && this.props.user.ClientName
                ?
                  <a href="/virtual-sports/BR/no">
                    <img className="mnu-icn"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/virtul-sports-wc.png"
                      }
                      alt="Virtual Sports"
                      title="Virtual Sports"
                    />
                    Virtual Sports
                  </a>
                :
                  <a href="/" onClick={() => notify("Please Login first", "error", "top-right")}>
                    <img className="mnu-icn"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/virtul-sports-wc.png"
                      }
                      alt="Virtual Sports"
                      title="Virtual Sports"
                    />
                    Virtual Sports
                  </a>
              }
              
            </li>
            <li>
              {
                this.props.user && this.props.user.ClientName
                ?
                  <a href="/sports-book/BT/no">
                    <img
                      className="mnu-icn"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/sportsbook-n.png"
                      }
                      alt="SPORTBOOK"
                      title="SPORTBOOK"
                    />
                    SportsBook
                  </a>
                :
                  <a href="/" onClick={() => notify("Please Login first", "error", "top-right")}>
                    <img
                      className="mnu-icn"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/sportsbook-n.png"
                      }
                      alt="SPORTBOOK"
                      title="SPORTBOOK"
                    />
                    SportsBook
                  </a>
              }
            </li>
            <li>
              {
                this.props.user && this.props.user.ClientName
                ?
                  <a href="/Casino/GT/thimbles">
                    <img
                      className="mnu-icn"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/icon/new/cards-icon.gif"
                      }
                      alt="evolution"
                      title="evolution"
                    />
                    Instant Win
                  </a>
                :
                  <a href="/" onClick={() => notify("Please Login first", "error", "top-right")}>
                    <img
                      className="mnu-icn"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/icon/new/cards-icon.gif"
                      }
                      alt="evolution"
                      title="evolution"
                    />
                    Instant Win
                  </a>
              }
              
            </li>
            <li>
              {
                this.props.user && this.props.user.ClientName
                ?
                  <a href="/Casino/GT/no">
                    <img className="mnu-icn" alt="LiveCasino" src="/assets/images/LiveCasino.png" />
                    Live Casino
                  </a>
                :
                  <a href="/" onClick={() => notify("Please Login first", "error", "top-right")}>
                    <img className="mnu-icn" alt="LiveCasino" src="/assets/images/LiveCasino.png" />
                    Live Casino
                  </a>
              }
              
            </li>
            {/* <li>
            {
                this.props.user && this.props.user.ClientName
                ?
                  <a href="/#/slot-games/GT/no">
                    <img className="mnu-icn" src="/assets/images/slot.png" />
                    Slot Games
                  </a>
                :
                  <a href="#" onClick={() => notify("Please Login first", "error", "top-right")}>
                    <img className="mnu-icn" src="/assets/images/slot.png" />
                      
                  </a>
              }
              
            </li> */}
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

DefaultsideNav.propTypes = propTypes;
DefaultsideNav.defaultProps = defaultProps;

export default withRouter(DefaultsideNav);
